.wrapper {
  overscroll-behavior-y: contain;

}

.body,
.wrapper {
  
  /* Break the flow */
  position: absolute;
  top: 0px;

  /* Give them all the available space */
  width: 100%;
  height: 100%;

  /* Remove the margins if any */
  margin: 0;

  /* Allow them to scroll down the document */
  /* overflow-y: hidden; */
}

.body {
  /* Sending body at the bottom of the stack */
  z-index: 1;
}

.wrapper {
  /* Making the wrapper stack above the body */
  z-index: 2;
}


.App {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  background-color: #FFFFFF;
  margin: auto;
}

.tt-score-button {
  border-radius: 50%;
  height: 30px;
  width: 30px;
    background-color: #fff;
    border: 1px solid #009688 !important;
    color: #009688;
    border: none;
    display: inline-block;
    /* padding: 8px 16px; */
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    /* color: inherit;
    background-color: inherit; */
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
}

@media only screen and (min-width: 768px) {
  .App {
    border-left: 1px solid #535050;
    border-right: 1px solid #535050;
    border-bottom: 1px solid #535050;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
